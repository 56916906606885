import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Shareideasbreadcreumb from "../components/connect/shareideas/shareideas-breadcumb";

import Shareideasseccontent from "../components/connect/shareideas/shareideas-sec-content";

// markup
const newideasPage = () => {
  return (
    <div>
      <Seo title="HEARTFULNESS OFFERINGS" />

      <Layout isSticky>
        <section>
          <Shareideasbreadcreumb />
        </section>
        <section>
          <Shareideasseccontent />  
        </section>

      </Layout>
    </div>
  );
};

export default newideasPage;
